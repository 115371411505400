/* Reset some default styles */

.nav-links a {
  color: white;
  margin-right: 20px;
  text-decoration: none;
}

/* Sidebar */
.sidebar {
  width: 250px;
  background-color: #444;
  color: white;
  height: 100vh;
  left: 0;
  position: fixed;
  padding: 20px;
}
.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar ul {
  padding: 0;
}

.sidebar li {
  margin-bottom: 10px;
}
.sidebar li:hover {
  cursor: pointer;
  text-decoration: underline;
}
/* Main content */
.main-content {
  flex-grow: 1;
  position: relative;
  left: 21%;
  top: 25%;
  max-width: 75vw;
  padding: 20px;
}

/* Add more styling as needed */
.company-list {
  max-width: 77vw;
}
.company-list .card {
  margin: 2px;
}
.company-list .card :hover {
  cursor: pointer;
}

.company-detail .card {
  box-shadow: 0 0 10px 2px rgba(66, 165, 66, 0.616);
  margin: 5px;
}
.company-detail {
   text-align: center;
}

.company-detail .card img {
  width: 100%;
}
/* Add this CSS in your stylesheet */
.card-enter {
  opacity: 0;
  transform: translateX(-2px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.card-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s ease, transform 0.3s ease;
}
