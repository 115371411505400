.cart-element,
.like-product {
  color: var(--primary-color);
}
.user-interaction {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 0%;
  top: 0%;
  padding: 7px;
  padding-left: 3px;
  border-radius: 0 15px;
  cursor: default;
  height: min-content;
  
}
.Product-Detail .user-interaction {
  z-index: 10;
}
.user-interaction:hover {
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s, height 0.3s;
}

.like-product i {
  font-size: 18px;
  cursor: pointer;
}
.like-product i:hover,
.like-product .liked {
  color: var(--secondary-color);
}
.like-product .like-count {
  color: white;
  width: 70%;
  font-family: sans-serif;
  font-size: 13px;
  text-align: right;
  transform: translateY(-2px);
}
.cart-text {
  display: none;
}
.display-cart-text {
  display: block;
  position: absolute;
  width: max-content;
  right: 22px;
  color: white;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s, height 0.3s;
  top: -5px;
  z-index: 20;
  border-radius: 50px;
}
.cart-element {
  position: relative;
  cursor: pointer;
}


.cart-displayer {
  position: fixed;
  right: 0;
  top: 8%;
  min-width: 30vw;
  z-index: 20;
  max-height: 85vh;
  border-radius: 8px;
  max-width: 60vw;
  padding: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: var(--bg-wt);
  box-shadow: 0 2px 6px 1px rgba(75, 94, 71, 0.384);
}
.cart-item {
  display: flex;
}
.cart-item .cart-placeholder{
  display: none;
}
.close-cart {
  font-size: 28px;
  font-weight: 900;
  cursor: pointer;
  cursor: pointer;
}
@media screen and (max-width: 780px) {
  .cart-displayer {
    max-height: 83vh;
    right: 1%;
    max-width: 94%;
  }
  .cart-item {
    display: block;
  } .cart-item .btn{
    margin: auto;
  }
  .cart-item .buyer-action-buttons .btn{
    margin: 3px 5px;
  } 
}
