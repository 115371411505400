.profile-info {
  margin: 5px;
  background-color: var(--bg-wt);
  border-radius: 8px;
  padding: 10px;
  overflow-x: hidden;
}


@media screen and (max-width: 1000px){
  .profile-info{
    max-width: 97%;
  }
}