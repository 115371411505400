.overlay,
.overlay-transp {
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  top: 0; 
  cursor: pointer;
  left: 0; 
  z-index: 15;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.556);
}
