.loading-card {
  background: #ccccccd3; /* Gray background color */
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.144);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 1s infinite alternate;
  width: 100%;
  height: 100px;
}

.loading-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin: 10px;
}

.large-card {
  background: #ccccccd3;
  border-radius: 8px;
  width: 100px;
  height: 60px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: pulse 1s infinite alternate;
  margin-right: 26px;
}
.right-cards {
  display: block;
}
.small-card {
  background: #ccccccd3;
  border-radius: 8px;
  width: 300px;
  height: 15px;
  margin: 3px;
  margin-bottom: 13px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: pulse 1s infinite alternate;
}
.little-loader .modal-content {
  padding: 10px 15px;
}
@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.loading-container-vert {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.big-card-vert {
  background: #ccccccd3;
  border-radius: 8px;
  width: 97%;
  align-self: center;
  height: 120px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: pulse 1s infinite alternate;
  margin-bottom: 16px;
}

.small-card-vert {
  background: #ccccccd3;
  border-radius: 8px;
  justify-self: left;
  width: 80%;
  height: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: pulse 1s infinite alternate;
  margin-bottom: 16px;
  margin-left: 5px;
}
