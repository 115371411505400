.rating {
    font-size: 24px;
    color: #ffc107; /* Star color */
    display: inline-block;
  }
  
  .star {
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .star.filled,
  .star:hover {
    color: #e6900f; /* Filled star color */
  }
  