.nav-menu .right-side-nav {
  text-align: center;
}

.right-side-nav-user {
  position: relative;
}
.mobile-nav {
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 10;
  background-color: var(--bg-wt);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.53);
  margin: 0;
}
.mobile-nav ul {
  list-style: none;
  margin: 1px;
  padding: 5px;
  padding-top: 17px;
  display: flex;
  font-size: 24px;
  overflow-y: hidden;
  padding-left: 0;
  width: 100vw;
  text-align: center;
  justify-content: space-around;
}
.mobile-nav .user-login-action {
  font-size: 26px;
  padding: 1px 6px;
  transform: translateY(-20%);
}
.mobile-nav .user-pr{
  padding: 2px 5px;
}
.notification-num {
  color: white;
  position: absolute;
  padding: 0px 5px;
  background-color: var(--secondary-color);
  font-weight: 600;
  font-size: 16px;
  border-radius: 50%;
  top: -40%;
  right: -40%;
}
.order-pg .Category-bar {
  display: none;
}
.user-pr{
  border-radius: 50%;
  width: 33px;
  aspect-ratio: 1;
  overflow: hidden;
  border: 1px solid gray;

}
.user-pr img {
  width: 99%;
  object-position: center;
  padding: 0;
}
@media screen and (max-width: 780px) {
  .navBar-header {
    flex-wrap: wrap;
    height: max-content;
    max-height: max-content;
    padding-bottom: 2px;
   
  }
  .navBar-header .logo{
    width: max-content; 
  }
  .navBar-header i {
    font-size: 18px;
  }
  .nav-menu {
    width: 98vw;
    margin: auto;
  }
  .nav-menu .right-side {
    margin: 0px;
    transform: translateX(-15%);
  }
  .right-side ul li {
    margin-right: 0;
    padding: 0;
  }
  .logo {
    font-size: large;
  }
  .mobile_hidden {
    display: none;
  }

  .nav-menu .right-side-nav {
    margin-right: 8px;
    text-align: center;
  }
  .mobile-nav {
    display: flex;
  }
  .user-pr{
    width: 20px;
    height: 26px;
    left: 0;
  }
}

