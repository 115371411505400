.seler-pic {
  width: 80px;
  aspect-ratio: 1;
  margin: auto;
}
.user-information {
  max-width: 100%;
  margin: auto;
}
.user-information > div {
  width: max-content;
  margin: auto;
}
.user-information table {
  width: 90%;
  min-width: 300px;
  margin: auto;
}
.user-information table tr td:nth-child(odd) {
  text-align: right;
}
/*SELLER SECTION START HERE*/
.SellerConf {
  max-width: 70%;
  margin: auto;
}
.SellerConf .card {
  border-radius: 10px;
  max-height: max-content;
}
.contact-user {
  max-width: 90%;
}
.contact-user ul {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  max-width: 90%;
}
.seller-pg {
  position: fixed;
  margin: 0;
  top: 0;
}
.seller-pg .right-side {
  width: 70vw;
  align-content: center;
  margin: auto;
}
.seller-profile-header {
  margin-top: 52px;
}

@media screen and (max-width: 700px) {
  .seller-pg {
    width: 70vw;
    margin: 0;
    text-align: left;
  }
}
/*SELLER SECTION END HERE*/
