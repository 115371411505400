/* Modal.css */
.modal {
  position: fixed;
  top: 0;
  left: 0; 
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px 40px;
  border-radius: 4px;
  width: max-content;
  position: relative;
  max-width: 500px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.modal-content .registration-form {
  width: 400px;
}

.modal-close {
  position: absolute;
  top: 4%;
  right: 3%;
  z-index: 10;
  cursor: pointer;
  font-size: 34px;
  font-weight: 900;
  color: #f71212;
  transition: transform 0.3s; /* Add a transition for smooth animation */
}

.modal-close:hover {
  transform: rotate(180deg); /* Rotate 270 degrees on hover */
}
@media screen and (max-width: 780px) {
  .modal-content {
    max-width: 90%;
    padding: 17px 10px;
  }
}
