.gallery-title {
  margin: auto;
  margin-top: 0;
}
.gallery-title .Header-short-line {
  margin-top: 0;
  color: var(--secondary-color);
  font-family: "Montserrat", sans-serif;
}

.grid_gallery {
  margin: 5px auto;
  width: 98%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
  grid-gap: 5px;
}
.grid_gallery .photo {
  width: 100%;
  height: auto;
}
.photo img {
  width: 100%;
  aspect-ratio: 1;
  max-height: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}
/* editable */
.word {
  font-size: 36px;
  font-weight: bold;
  position: relative;
}

.green-gradient {
  background-clip: text;
  -webkit-background-clip: text;
  background: linear-gradient(
    to bottom right,
    transparent 49.5%,
    var(--primary-color) 49.5%
  );
}
.gallery-bg-image {
  width: fit-content;
  max-width: 80vw;
  max-height: 100vh;
}
.gallery-bg-image img {
  max-height: 100vh;
  width: auto;
  max-width: 80vw;
  object-fit: contain;
}
@media screen and (max-width: 780px) {
  .gallery-bg-image {
    max-width: 99vw;
    max-height: auto;
    min-height: none;
  }
  .gallery-bg-image img{
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: none;
    max-height: 100vh;
  }
}
