@import url("https://fonts.googleapis.com/css2?family=Mooli&family=Roboto:ital,wght@0,100;0,700;0,900;1,900&display=swap");

.landing-banner {
  width: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  border-radius: 0 0 40px 40px;
}
.landing-banner .navBar-header {
  position: relative;
  background-color: transparent;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.226);
}
.banner-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  min-height: 500px;
  background: rgba(8, 10, 37, 0.936);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-image 1s ease-in-out;
}
.banner-bg::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.685);
  background-color: #2a255071;
}
.banner-content {
  position: relative;
  color: white;

  margin: auto;
  margin-left: 8%;
  width: 60%;
  padding: 10px;
}
.banner-content h1 {
  margin-top: 50px;
  font-size: 40px;
  font-family: roboto;
  font-weight: 900;
}
.banner-content p {
  margin-top: 35px;
  font-family: roboto;
  font-weight: 600;
  font-size: 16px;
  text-align: justify;
  text-justify: inter-word;
  text-indent: 8px;
}
.banner-content p a {
  margin-left: 5px;
  color: var(--primary-color);
  max-width: 96%;
}
.landing-banner .search-form {
  width: 90%;
  padding: 0;
}
.landing-banner .search-form .search-container {
  width: 100%;
  position: relative;
}
.landing-banner .search-form .search-container input {
  width: 100%;
  padding: 16px;
  padding-right: 110px;
}
.landing-banner .search-form .search-container button {
  padding: 12px;
  border-radius: 25px;
  position: absolute;
  right: 1%;
}
.landing-banner .interact-user {
  position: absolute;
  right: 2%;
  color: white;
  top: 10%;
}
.landing-banner .interact-user ul {
  list-style: none;
  width: 100%;
  display: flex;
  margin: 0;
}
.landing-banner .interact-user ul li {
  margin: 0 10px;
  font-weight: 600;
  font-family: sans-serif;
  border: 1px solid var(--primary-color);
  background: rgba(19, 18, 18, 0.281);
  border-radius: 5px;
  padding: 2px;
  font-size: 15px;
}
.interact-user .link:hover {
  color: var(--primary-color);
  cursor: pointer;
}

@media screen and (max-width: 780px) {
  .landing-banner .interact-user {
    position: relative;
    width: 100vw;
    right: 34px;
  }
  .landing-banner .interact-user ul {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .landing-banner .interact-user ul li {
    margin: 4px;
    justify-content: space-between;
  }
  .banner-content {
    width: 90%;
    margin-left: 0;
    margin: auto;
  }
  .banner-content * {
    margin: auto;
  }
  .banner-content h1 {
    font-size: 24px;
    margin: 50px auto;
    font-weight: 700;
  }
  .banner-content p {
    font-size: 14px;
    font-weight: 550;
    display: none;
    margin: 0;
    margin-top: 35px;
  }
  .landing-banner .search-form {
    margin: 15px;
    width: 90%;
    padding: 0;
  }
  .landing-banner .search-form .search-container {
    width: 100%;
    background-color: white;
  }
  .landing-banner .search-form .search-container input {
    min-width: 60%;
    width: 100%;
  }
  .landing-banner .search-form .search-container button {
    margin: 0;
  }
}
@media screen and (max-width: 580px) {
  .landing-banner {
    min-height: 70vh;
  }
  .banner-content {
    padding: 0px;
    width: 95%;
  }
  .landing-banner .search-form {
    width: 100%;
    margin: 15px auto;
  }
}
