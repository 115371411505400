/*Search product style*/
.search-engine-page {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  max-width: 98.5vw;
  align-self: center;
  width: 97vw;
  justify-self: center;
  overflow-x: hidden;
  margin: auto;
  margin-bottom: 13%;
}
.search-form {
  margin: 0;
  padding: 0px 15px;
  padding-right: 0;
  border: 2px solid var(--primary-color);
  border-radius: 50px;
  background-color: transparent;
  overflow: hidden;
}
.search-container {
  margin: auto;
  padding: 0;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.order-pg .search-form {
  display: none;
}
.search-input {
  border: none;
  background-color: white;
  outline: none;
  font-size: 16px;
}
.search-button {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 6px;
  font-size: medium;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  background-color: #14f79c;
  background-color: var(--primary-color);
  margin-left: 10px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background-color: #14f79c;
}

.search-button i {
  font-size: medium;
  color: white;
}

.search-engine-result {
  width: 80%;
}
.Search-result-displayer {
  margin: 3px auto;
  display: flex;
  width: 95%;
  background-color: white;
  max-height: 190px;
  padding: 0.7%;
  box-shadow: 0 2px 6px 1px rgba(75, 94, 71, 0.384);
}
.Search-result-displayer:hover {
  box-shadow: 0 2px 6px 2px #08e98fa2;
}
.Search-result-displayer .result_product {
  min-width: 25%;
  max-width: 26%;
  overflow: hidden;
  margin-right: 5px;
}
.result_detail {
  width: 100%;
}
.result_detail_info {
  width: 100%;
  border-bottom: 1px solid gray;
  padding-bottom: 0.5rem;
}
.result_detail_info .price {
  font-weight: 700;
  padding: 5px 2px;
}
.numberOfProduct {
  font-weight: 400;
  font-size: medium;
}
.Search-result-displayer .result_product .card-img {
  min-width: 90%;
  cursor: pointer;
  height: auto;
  max-height: 100%;
  object-fit: cover;
  object-position: center;
}
.link-primary {
  text-decoration: none;
  color: inherit;
}
.link-primary:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
.buyer-action-buttons :nth-child(even) {
  margin: 13px;
}
.Seller-detail-info {
  cursor: pointer;
  padding: 3px 0;
  font-size: medium;
  padding-bottom: 6px;
}
.Seller-detail-info :nth-child(even) {
  margin: 5px;
}
.result_detail_info .rate :nth-child(even) {
  margin: 5px;
}
.result_detail_seller_info .rate .fa-star {
  color: gray;
}

.custom-prev-button,
.custom-next-button {
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  color: black;
  font-size: 50px;
  cursor: pointer;
  z-index: 2;
}

.custom-prev-button {
  left: 10px;
}

.custom-next-button {
  right: 10px;
}
.filter-type {
  margin: 3px;
}
.filter-type h5 {
  margin-bottom: 3px;
  font-weight: 900;
  font-size: medium;
}
.link-primary {
  cursor: pointer;
}
.filter-type > ul {
  margin: 0;
  list-style: none;
}
/*search end here*/
@media screen and (max-width: 1000px) {
  .search-form {
    padding: 0px 2px;
  }
  .search-button {
    padding: 3px;
    margin-left: 0px;
  }

  .search-engine-result,
  .search-engine-filter {
    justify-self: center;
    display: inline;
    max-height: min-content;
    width: 100%;
    margin: 3px auto;
  }
  .result_detail_info,
  .result_detail_info div {
    font-size: 14px;
  }
  .result_detail_info {
    padding-top: 5px;
  }
  .Search-result-displayer {
    max-height: 200px;
  }
  .buyer-action-buttons :nth-child(even) {
    margin: 6px;
  }
  .result_detail_seller_info {
    font-size: 14px;
  }
}
@media screen and (max-width: 780px) {
  .search-engine-page {
    max-width: 99vw;
    display: block;
    justify-content: center;
    margin: auto;
    margin-bottom: 13%;
  }
  .Search-result-displayer .result_product .card-img {
    min-width: 90%;
    cursor: pointer;
    height: auto;
    max-height: 90%;
    object-fit: cover;
    object-position: center;
  }

  .navBar-header.mobile {
    justify-content: center;
  
  }
  .navBar-header.mobile .logo{
    margin-right: 16px;
  }
  .search-input {
    max-width: 40vw;
    text-align: left;
    margin: auto;
  }
  .search-form {
    margin: auto;
    padding: 0;
    width: 60vw;
    display: flex;
    flex-wrap: nowrap;
  }
  .search-container {
    width: 100%;
    justify-self: center;
    margin: auto;
  }
  .search-button {
    margin: 0;
  }
  .buyer-action-buttons .btn {
    padding: 4px 8px;
    font-size: 15px;
  }
}
